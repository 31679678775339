import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, finalize, first, map, Observable, of } from 'rxjs';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogOptions,
  NotificationService,
  Severity,
  TableComponent,
  TableConfiguration,
} from '@digital-factory/ds-common-ui';

import { STATUS_DISPLAY } from 'src/info-model/enums/status';
import { TenantUsersService } from '../../services/tenant-users.service';
import { ROUTE_PATH } from '../app-routing';
import { BaseSubscriptionsDirective } from '../shared/base-subscriptions/base-subscriptions.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TenantsService } from '../../services/tenants.service';
import { UserAddEditViewParams } from './user-add-edit-view/user-add-edit-view';
import { ApplicationsService } from '../../services/applications.service';
import { Application } from '../../info-model/application';
import { GenericRecord } from '../../info-model/generic';
import { Tenant } from '../../info-model/tenant';
import { TenantUser } from '../../info-model/tenant-user';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent extends BaseSubscriptionsDirective implements OnInit {
  @ViewChild(TableComponent) table!: TableComponent;

  config: TableConfiguration = {
    name: 'UserManagement-UsersTable',
    pageSize: 10,
    dataSource: EMPTY,
    columns: [],
    actions: [],
    emptyMessage: 'No users match the search criteria',
  };

  isEmpty = true;
  isLoading = true;
  application!: Application;
  tenant!: Tenant;
  readonly routePath = ROUTE_PATH;
  user_id!: string;

  private readonly applicationsService = inject(ApplicationsService);
  private readonly dialog = inject(MatDialog);
  private readonly institutionsService = inject(TenantsService);
  private readonly location = inject(Location);
  private readonly notificationService = inject(NotificationService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly usersService = inject(TenantUsersService);

  actionAddUser() {
    const { usersService, tenant } = this;
    const params: UserAddEditViewParams = {
      userId: '0',
      tenantId: tenant.tenant_id,
      mode: 'add',
    };
    this.router.navigate([...usersService.navigate(tenant.tenant_id, '0')], { queryParams: params });
  }

  ngOnInit(): void {
    const { router, destroyRef, route, institutionsService, applicationsService, usersService, location } = this;

    applicationsService.initialize().subscribe(() => {
      route.params.pipe(takeUntilDestroyed(destroyRef)).subscribe((params) => {
        const tenantId = params['tenantId'];
        const userId = params['user_id'];

        if (tenantId) {
          if (userId) {
            this.user_id = userId;
            // Join will fail with the leading slash
            const path = usersService.navigate(tenantId).slice(1).join('/');
            location.replaceState(path);
          }

          institutionsService.getTenant$(tenantId).subscribe((tenant: Tenant) => {
            this.tenant = tenant;
            this.application = applicationsService.get(tenant.application);
            this.initialize();
          });
        } else {
          router.navigate([ROUTE_PATH.error, '404']);
        }
      });
    });
  }

  private initialize() {
    const { router, usersService, tenant, applicationsService, application } = this;

    this.config = {
      ...this.config,
      dataSource: this.getData(true),
      columns: [
        {
          key: 'user_name',
          label: 'E-Mail',
          isSortable: true,
        },
        {
          key: 'first_name',
          label: 'First Name',
        },
        {
          key: 'last_name',
          label: 'Last Name',
        },
        {
          key: 'department',
          label: 'Department',
        },
        {
          key: 'role',
          label: 'Role',
          valueMap: applicationsService.kvsToRecord(application.user_roles),
        },
        {
          key: 'status',
          label: 'Status',
          valueMap: STATUS_DISPLAY,
        },
      ],
      actions: [
        {
          label: 'View User',
          icon: 'icon-eye',
          callback: (element) => {
            if (element) {
              const row = element as unknown as TenantUser;
              const params: UserAddEditViewParams = {
                tenantId: tenant.tenant_id,
                userId: row.user_name,
                mode: 'view',
              };

              router.navigate([...usersService.navigate(tenant.tenant_id, row.user_name)], {
                queryParams: params,
              });
            }
          },
        },
        {
          label: 'Update User',
          icon: 'icon-pencil',
          callback: (element) => {
            if (element) {
              const row = element as unknown as TenantUser;
              const params: UserAddEditViewParams = {
                tenantId: tenant.tenant_id,
                userId: row.user_name,
                mode: 'edit',
              };

              router.navigate([...usersService.navigate(tenant.tenant_id, row.user_name)], {
                queryParams: params,
              });
            }
          },
        },
        {
          label: 'Delete User',
          icon: 'icon-trash',
          callback: (element) => {
            this.delete(element as unknown as TenantUser);
          },
        },
      ],
    };
    this.config.defaultSortColumn = this.config.columns[0].key;
  }

  private refreshTable() {
    this.table.selected = null;
    this.config = {
      ...this.config,
      dataSource: this.getData(true),
    };
  }

  private mapUsersToRows(users: TenantUser[]): GenericRecord[] {
    this.isEmpty = users.length === 0;
    this.isLoading = false;
    return users as unknown as GenericRecord[];
  }

  private onError() {
    this.config = {
      ...this.config,
      dataSource: this.getData(false),
    };
  }

  private delete(user: TenantUser) {
    const name = `"${user.first_name} ${user.last_name}"`;
    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogOptions, boolean>(
      ConfirmationDialogComponent,
      {
        data: {
          title: 'Delete User',
          message: `Are you sure you want to delete ${name}?`,
          cancelButtonText: 'Cancel',
          okButtonText: 'Delete',
          okButtonId: 'Delete',
        },
      },
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.usersService
          .deleteUser$(user.user_name)
          .pipe(first())
          .subscribe(() => {
            this.refreshTable();
            this.notificationService.showMessage(`The user ${name} was deleted.`, Severity.Success);
          });
      }
    });
  }

  private getData(shouldLoadData: boolean): Observable<GenericRecord[]> {
    if (shouldLoadData) {
      let rx: TenantUser[] = [];

      return this.usersService
        .getUsers$(this.tenant.tenant_id, () => this.onError())
        .pipe(
          map((rows) => {
            rx = rows;
            return this.mapUsersToRows(rows);
          }),
          finalize(() => {
            const { user_id } = this;

            if (user_id) {
              this.user_id = '';
              setTimeout(() => {
                const { table } = this;
                if (table) {
                  const row = rx.find((r) => r.user_name === user_id);

                  if (row) {
                    table.filterControl.setValue(row.user_name);
                  }
                }
              }, 500);
            }
          }),
        );
    } else {
      this.isEmpty = true;
      this.isLoading = false;
      return of([]);
    }
  }
}
