import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ADD_EDIT_VIEW_NODES, AddEditViewMode, InstitutionAddEditViewParams } from './institution-add-edit-view';
import { TenantsService } from '../../../services/tenants.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ROUTE_PATH } from '../../app-routing';
import {
  ConfirmationDialogComponent,
  IdFormatterService,
  NotificationService,
  Severity,
} from '@digital-factory/ds-common-ui';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EMAIL_REGEX, NAME_REGEX, PHONE_NUMBER_REGEX } from '../../../info-model/constants/form-validators.constants';
import { Application } from '../../../info-model/application';
import { STATUS, STATUS_DISPLAY, StatusKey } from '../../../info-model/enums/status';
import { LOGIN, LOGIN_DISPLAY, LoginKey } from '../../../info-model/enums/login';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationsService } from '../../../services/applications.service';
import { Tenant, TenantCreateUpdate, TenantLocation } from '../../../info-model/tenant';
import { KeyValue } from '@angular/common';

type MyForm = Omit<Tenant, 'links' | 'locations' | 'tenant_id' | 'tenant_type'> & TenantLocation;
type CopyType = Extract<keyof MyForm, 'client_id' | 'gateway_key' | 'client_secret'>;

@Component({
  templateUrl: './institution-add-edit-view.component.html',
  styleUrl: './institution-add-edit-view.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class InstitutionAddEditViewComponent extends BaseFormDirective<MyForm> implements OnInit {
  /**
   * The error message for chars/email/phone are the same. If/when the dust settles. they can be combined.
   * This is shared with the user screen.
   */
  static readonly ERROR = {
    email: 'Contains invalid text',
    generic: 'Contains invalid text', // the remaining inputs after email and phone
    phone: 'Contains invalid text',
    required: 'Required Field',
  };
  // This is a approx, b/c of UTC issues. It's not important that the exact date range is honored.
  static readonly DATE_RANGE = {
    min: new Date(2019, 11, 31),
    max: new Date(2100, 11, 31),
  };

  readonly applicationsService = inject(ApplicationsService);
  application: Readonly<Application> | undefined;
  clientSecretVisible = false;
  readonly copyTooltip = 'Copy to the clipboard';
  readonly idFormatterService = inject(IdFormatterService);
  readonly loginDisplay = LOGIN_DISPLAY;
  readonly loginDisplayKeys = Object.keys(LOGIN_DISPLAY) as LoginKey[];
  readonly statusDisplay = STATUS_DISPLAY;
  readonly statusDisplayKeys = Object.keys(STATUS_DISPLAY) as StatusKey[];
  readonly error = InstitutionAddEditViewComponent.ERROR;
  mode!: AddEditViewMode;
  myForm!: MyForm;
  readonly routePath = ROUTE_PATH;
  title!: string;

  private readonly dialog = inject(MatDialog);
  private readonly institutionsService = inject(TenantsService);
  private readonly notificationService = inject(NotificationService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private tenant!: Tenant;

  actionClipboard(copyType: CopyType) {
    const { notificationService, tenant } = this;
    const value = tenant[copyType];
    let name: string;

    switch (copyType) {
      case 'client_id':
        name = 'Client ID';
        break;
      case 'client_secret':
        name = 'Client Secret';
        break;
      case 'gateway_key':
        name = 'Gateway Key';
        break;
    }

    navigator.clipboard.writeText(value);
    notificationService.showMessage(`Copied to the clipboard: ${name}`, Severity.Success);
  }

  actionSave() {
    const { mode, institutionsService, rawValue, router, notificationService, form, tenant } = this;
    const {
      application,
      contact_email,
      contact_firstname,
      contact_lastname,
      contact_phone,
      country,
      customer_id,
      date_format,
      gateway_key,
      language,
      license_expiration_date,
      license_options,
      license_start_date,
      location_name,
      login_type,
      number_format,
      region,
      regulatory_entity,
      status,
      tenant_name,
      tier,
      time_zone,
    } = rawValue;
    const nav = () => {
      const message = `The Institution "${tenant_name}" has been ` + (mode === 'edit' ? 'updated' : 'added');

      form.markAsPristine();
      notificationService.showMessage(message, Severity.Success);
      router.navigate([ROUTE_PATH.institutions, { tenant_name }]);
    };
    const createUpdate: TenantCreateUpdate = {
      application,
      contact_email,
      contact_firstname,
      contact_lastname,
      contact_phone,
      country,
      customer_id,
      date_format,
      gateway_key,
      language: language,
      login_type,
      number_format,
      region,
      regulatory_entity,
      status,
      tenant_name,
      locations: [
        {
          license_expiration_date,
          license_options: license_options.filter((o) => !!o),
          license_start_date,
          location_name,
          tier,
          time_zone,
        },
      ],
    };

    if (mode === 'add') {
      institutionsService.createTenant$(createUpdate).subscribe(() => nav());
    } else {
      institutionsService.updateTenant$(createUpdate, tenant.links).subscribe(() => nav());
    }
  }

  canDeactivate(): boolean | Observable<boolean> {
    const { form, dialog } = this;

    if (form.dirty) {
      return dialog
        .open(ConfirmationDialogComponent, {
          data: {
            title: 'Leave Institution',
            message: 'Are you sure you want to leave this institution without saving your edits?',
            cancelButtonText: 'Cancel',
            okButtonText: 'Yes',
            okButtonId: 'Yes',
          },
        })
        .afterClosed();
    }

    return true;
  }

  ngOnInit() {
    const { route, router, destroyRef, applicationsService } = this;

    applicationsService.initialize().subscribe(() => {
      route.queryParams.pipe(takeUntilDestroyed(destroyRef)).subscribe((p: Params) => {
        const params = p as InstitutionAddEditViewParams;

        if (ADD_EDIT_VIEW_NODES.includes(params.mode)) {
          this.mode = params.mode;
          switch (this.mode) {
            case 'add':
              this.title = 'Add Institution';
              break;
            case 'edit':
              this.title = 'Update Institution -';
              break;
            case 'view':
              this.title = 'View Institution (Read Only) -';
              break;
          }

          if (this.mode === 'add') {
            this.initializeTenant();
          } else {
            this.getTenant(params.id);
          }
        } else {
          router.navigate([ROUTE_PATH.error, '404']);
        }
      });
    });
  }

  private getTenant(tenantId: string) {
    const { institutionsService, applicationsService } = this;

    institutionsService.getTenant$(tenantId).subscribe((tenant: Tenant) => {
      this.tenant = tenant;
      this.myForm = { ...tenant, ...tenant.locations[0] };
      this.application = applicationsService.get(tenant.application);

      this.initializeForm();
    });
  }

  private initializeTenant() {
    this.myForm = {
      application: '',
      client_id: '',
      client_secret: '',
      contact_email: '',
      contact_firstname: '',
      contact_lastname: '',
      contact_phone: '',
      country: '',
      customer_id: '',
      date_format: '',
      gateway_key: '',
      language: '',
      license_expiration_date: '',
      license_options: [''],
      license_start_date: '',
      location_name: '',
      login_type: LOGIN.LOCAL,
      number_format: '',
      region: '',
      regulatory_entity: '',
      status: STATUS.ACTIVE,
      tenant_name: '',
      tier: '',
      time_zone: '',
    };

    this.initializeForm();
  }

  private initializeForm() {
    const { myForm, mode } = this;
    const {
      application,
      client_id,
      client_secret,
      contact_email,
      contact_firstname,
      contact_lastname,
      contact_phone,
      country,
      customer_id,
      date_format,
      gateway_key,
      language,
      license_expiration_date,
      license_options,
      license_start_date,
      location_name,
      login_type,
      number_format,
      region,
      regulatory_entity,
      status,
      tenant_name,
      tier,
      time_zone,
    } = myForm;
    const { required } = Validators;
    const disabled = mode == 'view';
    const regex: Record<'email' | 'name' | 'phone', ValidatorFn> = {
      email: Validators.pattern(EMAIL_REGEX),
      name: Validators.pattern(NAME_REGEX),
      phone: Validators.pattern(PHONE_NUMBER_REGEX),
    };
    const expirationValidator = this.expirationValidator();

    this.formCreate({
      application: [{ value: application, disabled: mode !== 'add' }, required],
      client_id: [{ value: client_id || '', disabled: true }],
      client_secret: [{ value: client_secret || '', disabled: true }],
      contact_email: [{ value: contact_email, disabled }, regex.email],
      contact_firstname: [{ value: contact_firstname, disabled }, regex.name],
      contact_lastname: [{ value: contact_lastname, disabled }, regex.name],
      contact_phone: [{ value: contact_phone, disabled }, [regex.phone]],
      country: [{ value: country, disabled }],
      customer_id: [{ value: customer_id, disabled }, [required, regex.name]],
      date_format: [{ value: date_format, disabled }, required],
      gateway_key: [{ value: gateway_key || '', disabled: true }],
      language: [{ value: language, disabled }, required],
      license_expiration_date: [{ value: license_expiration_date, disabled }, [required, expirationValidator]],
      license_start_date: [{ value: license_start_date, disabled }, [required, expirationValidator]],
      license_options: [{ value: license_options, disabled }],
      location_name: [{ value: location_name, disabled }],
      login_type: [{ value: login_type, disabled }, required],
      number_format: [{ value: number_format, disabled }, required],
      region: [{ value: region, disabled }, regex.name],
      regulatory_entity: [{ value: regulatory_entity, disabled }],
      status: [{ value: status, disabled }, required],
      tenant_name: [{ value: tenant_name, disabled }, [required, regex.name]],
      tier: [{ value: tier, disabled }, required],
      time_zone: [{ value: time_zone, disabled }, required],
    });

    switch (mode) {
      case 'add':
        this.formAddInit();
        break;
      case 'edit':
        this.formEditValidation();
        break;
      case 'view':
        this.form.disable();
        break;
    }
  }

  /**
   * Shared date expiration validator for start and end date.
   */
  private expirationValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let result: ValidationErrors | null = null;

      if (this.form) {
        const { license_start_date, license_expiration_date } = this.controls;
        const { DATE_RANGE } = InstitutionAddEditViewComponent;

        if (license_start_date.value && license_expiration_date.value) {
          const start = new Date(license_start_date.value);
          const end = new Date(license_expiration_date.value);

          if (end <= start) {
            result = {
              error: 'License Expiration Date must be after License Start Date',
            };
          } else {
            if (start < DATE_RANGE.min || start > DATE_RANGE.max || end < DATE_RANGE.min || end > DATE_RANGE.max) {
              result = {
                error: 'Year must be between 2020 and 2100',
              };
            }
          }

          // Since the two controls are connected, a failure/success on one affects the other
          if (control === license_start_date) {
            license_expiration_date.setErrors(result);
          } else {
            license_start_date.setErrors(result);
          }
        }
      }

      return result;
    };
  }

  private formAddInit() {
    const { applicationsService } = this;

    this.application = applicationsService.applications[0]; // Arbitrarily default to the 1st application (GWL)

    const {
      application,
      application: {
        countries_default,
        date_formats_default,
        languages_default,
        name,
        number_formats_default,
        regulatory_entities_default,
        tiers_default,
      },
      controls: { regulatory_entity, application: appControl },
      destroyRef,
    } = this;

    this.patchValue({
      country: countries_default,
      date_format: date_formats_default,
      language: languages_default,
      license_expiration_date: '',
      license_options: [],
      license_start_date: '',
      number_format: number_formats_default,
      regulatory_entity: regulatory_entities_default,
      tier: tiers_default,
      time_zone: '',
    });

    appControl.setValue(name.key, { emitEvent: false });

    if (application.regulatory_entities.length > 0) {
      regulatory_entity.setValidators(Validators.required);
    } else {
      regulatory_entity.clearValidators();
    }

    appControl.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe((appName: string) => {
      this.application = applicationsService.get(appName);
      setTimeout(() => this.formAddInit(), 100); // Delay setting values
    });
  }

  /**
   * A tenant row can have "bad" data:
   * * Missing field
   * * Null field
   * * A select input has an invalid value, e.g., language: "abc" instead of "en"
   * This routine will:
   * * Validate fields
   * * Set defaults if possible
   * * Show errors so the user knows what to fix
   */
  private formEditValidation() {
    if (!this.application) {
      throw new Error('For mode=edit, application must be present via onInit');
    }

    const {
      form,
      application: {
        countries,
        countries_default,
        date_formats,
        date_formats_default,
        languages,
        languages_default,
        number_formats,
        number_formats_default,
        regulatory_entities,
        regulatory_entities_default,
        tiers,
        tiers_default,
        time_zones,
        time_zones_default,
      },
      controls: { country, date_format, language, number_format, regulatory_entity, tier, time_zone },
    } = this;
    let dirty = false;
    const fail = (kvs: KeyValue<string, string>[], control: FormControl) => {
      const result = !kvs.find((kv) => kv.key === control.value);
      if (result) {
        dirty = true;
      }
      return result;
    };

    form.markAllAsTouched(); // forces validation on all fields

    if (fail(countries, country)) {
      country.setValue(countries_default);
    }

    if (fail(date_formats, date_format)) {
      date_format.setValue(date_formats_default);
    }

    if (fail(languages, language)) {
      language.setValue(languages_default);
    }

    if (fail(number_formats, number_format)) {
      number_format.setValue(number_formats_default);
    }

    if (fail(regulatory_entities, regulatory_entity)) {
      regulatory_entity.setValue(regulatory_entities_default);
    }

    if (fail(tiers, tier)) {
      tier.setValue(tiers_default);
    }

    if (fail(time_zones, time_zone)) {
      time_zone.setValue(time_zones_default);
    }

    if (form.valid) {
      if (dirty) {
        form.markAsDirty(); // form is valid, but some fields were set
      } else {
        form.markAsPristine(); // form is unchanged
      }
    }
  }
}
