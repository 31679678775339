import { Component, ErrorHandler } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AuthenticationService,
  AuthorizationService,
  GlobalErrorHandler,
  NotificationService,
  Severity,
} from '@digital-factory/ds-common-ui';

import { environment } from 'src/environments/environment';
import { IdleService } from '../services/idle.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor(
    snackBar: MatSnackBar,
    notificationService: NotificationService,
    authenticationService: AuthenticationService,
    authorizationService: AuthorizationService,
    errorHandler: ErrorHandler,
    idleService: IdleService,
  ) {
    notificationService.setSnackBar(snackBar);

    const urlPrefix = environment.urlPrefix === 'prod' ? '' : `${environment.urlPrefix}.`;
    const cognitoApi = `https://auth.${urlPrefix}werfencloud.com`;
    const landingPage = `https://${urlPrefix}werfencloud.com`;
    const authorizationApi = `https://api.${urlPrefix}werfencloud.com/stratus-platform-management/v1/authenticate`;

    // Initialize authentication, authorization and idle services
    authenticationService.initialize(cognitoApi, environment.cognitoAppClientId, landingPage);
    authorizationService.initialize(authorizationApi);
    idleService.initialize();

    (errorHandler as GlobalErrorHandler).registerErrorHandler((error: Error) => {
      const code = Math.round(Math.random() * 10000);
      const message = `An unexpected error occurred. Please follow up with your Werfen support Contact. Error code: ${code}`;

      notificationService.showMessage(message, Severity.Error);
      console.error(message, error);
    });
  }
}
