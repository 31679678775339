import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { authInterceptor, DsCommonUiModule, GlobalErrorHandler } from '@digital-factory/ds-common-ui';

import { MatPaginatorIntl } from '@angular/material/paginator';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomPaginator } from './custom-paginator';
import { ErrorModule } from './error';
import { InstitutionManagementModule } from './institution-management';
import { UserManagementModule } from './user-management';
import { FrameComponent } from './frame/frame.component';
import { ProfileBarModule } from './frame/profile-bar';
import { idleInterceptor } from '../interceptors/idle.interceptor';

@NgModule({
  declarations: [AppComponent, FrameComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    DsCommonUiModule,
    UserManagementModule,
    InstitutionManagementModule,
    AppRoutingModule,
    ProfileBarModule,
    ErrorModule,
  ],
  providers: [
    { provide: Window, useValue: window },
    { provide: MatPaginatorIntl, useValue: new CustomPaginator() },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    provideHttpClient(withInterceptors([authInterceptor, idleInterceptor])),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
