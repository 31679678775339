import { inject, Injectable } from '@angular/core';
import { AuthenticationService, AuthorizationService } from '@digital-factory/ds-common-ui';
import { filter, take, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';

interface RefreshResponse {
  access_token_expiration: string;
}

/**
 * * Manage access_token_expiration and idle timeout.
 * * Idle timeout is optional
 */
@Injectable({
  providedIn: 'root',
})
export class IdleService {
  /**
   * `user.access_token_expiration` is OK if it's at least 10 minutes (arbitrary) from `Date.now()`
   */
  private static readonly ACCESS_TIME_FUTURE_MS = 10 * 60 * 1000;
  /**
   * Wait for startup routing to complete (approx). This only is relevant if a user is 100% idle
   * for the 1st interval.
   */
  private static readonly DELAY_FOR_ROUTING_MS = 10 * 1000;
  /** Set the timeout to 15 seconds (arbitrary) before actual idle timeout. */
  private static readonly IDLE_OFFSET_MS = 15 * 1000;
  /** Set an idle warning timer to 120 seconds (arbitrary) before actual timeout so users can be warned. */
  private static readonly IDLE_WARNING_OFFSET_MS = 120 * 1000;

  private readonly authenticationService = inject(AuthenticationService);
  private readonly authorizationService = inject(AuthorizationService);
  private readonly httpClient = inject(HttpClient);
  private readonly urlService = inject(UrlService);

  private idleHandler: undefined | (() => void);
  private lastRefreshDate = new Date();
  private lastHttpDate = new Date();

  /**
   * * Get the current user from the authorization service and then set up the timer.
   * * Align lastHttpAccess with the current time so idle timeout is accurate.
   *
   * @param idleHandler optional. If defined, then call the handler after the idle timer is triggered.
   * Otherwise, refresh. Examples:
   * * initialize(() => notificationService.showMessage('Two minute', Severity.Warning));
   * * initialize();  // application will log out when refresh expiration is exceeded
   */
  initialize(idleHandler?: () => void) {
    const {
      authorizationService: { currentUser$ },
    } = this;

    this.idleHandler = idleHandler;

    // take(1) b/c currentUser$ can only contain an authorized user.
    currentUser$
      .pipe(take(1))
      .subscribe(({ access_token_expiration }) => this.validateAccessTokenExpiration(access_token_expiration));
  }

  /**
   * Called by the idle interceptor and in the idle service
   *
   * @param setLastRefresh if true (internally), lastHttpDate = astHttpAccess
   */
  setLastHttpDate(setLastRefresh = false) {
    this.lastHttpDate = new Date();

    if (setLastRefresh) {
      this.lastRefreshDate = this.lastHttpDate;
    }
  }

  /**
   * POST to  the refresh endpoint, which refreshes cookies xxx_access_token and xxx_id_token
   *
   * Refresh response
   *  * success; returns the new access access_token_expiration.
   *  * error: refresh expiration has been exceeded. Automatically log the user out.
   */
  private refresh() {
    const { httpClient, urlService, authenticationService } = this;
    const url = urlService.getApiUrl('token/refresh');

    httpClient.post<RefreshResponse>(url, null).subscribe({
      next: ({ access_token_expiration }) => {
        this.setLastHttpDate(true);
        this.setTimers(access_token_expiration);
      },
      error: () => authenticationService.logout(),
    });
  }

  /**
   * * Set a timer based on the expiration time from token/refresh endpoint.
   * * The idle timer/handler is optional:
   * * * Implemented: Idle activity is monitored. The user will be warned prior to being logged out (e.g., 15 minutes).
   * * * Not implemented: Idle activity is not monitored. The user will be logged when the refresh time is exceeded (e.g., 24 hours).
   *
   * @param expirationString date string
   */
  private setTimers(expirationString: string) {
    const { idleHandler, lastRefreshDate, lastHttpDate, authenticationService } = this;
    const expirationDeltaMs = Date.parse(expirationString) - Date.now();
    const expiring = () => !!idleHandler && lastHttpDate <= lastRefreshDate;

    // idleHandler is optional. Applications using PHI most likely will implement the idle timer.
    if (idleHandler) {
      timer(expirationDeltaMs - IdleService.IDLE_WARNING_OFFSET_MS)
        .pipe(filter(() => expiring()))
        .subscribe(() => idleHandler());
    }

    // This timer will either log the user out if there is no activity or call refresh.
    timer(expirationDeltaMs - IdleService.IDLE_OFFSET_MS).subscribe(() => {
      if (expiring()) {
        authenticationService.logout();
      } else {
        this.refresh();
      }
    });
  }

  /**
   ```
   export interface User {
     access_token_expiration: string; // 2024-12-01T08:15:00Z
     userName: string;
     ...
   }
   ```
   * @param access_token_expiration ISO timestamp. AuthorizationService uses session storage for `User`. `#initialize`
   * calls this routine with `user.access_token_expiration`. `access_token_expiration` is also referred to as
   * TTL (time to live) or idle timeout. Assuming the time is 08:00 and idle timeout is 15 minutes,
   * `access_token_expiration=20xx-xx-xxT08:15:00Z`. There are two scenarios:
   * * User just logged in: `ACCESS_TIME_FUTURE_MS`=10 minutes. The routine determines that the expiration is
   * 15 minutes (more than 10) in the future and proceeds normally.
   * * User logged and hits refresh after being logged in for 6 minutes. The routine determines that 9 minutes (less than 10)
   * is left before idle timeout and calls `this.refresh`.
   *
   * While unlikely, the routine does validation on the timestamp because session storage is user accessible (bad actor).
   */
  private validateAccessTokenExpiration(access_token_expiration: string) {
    const now = Date.now();
    const expiration = Date.parse(access_token_expiration);

    // If the access time is invalid or the time isn't enough in the future call refresh
    if (Number.isNaN(expiration) || expiration - now < IdleService.ACCESS_TIME_FUTURE_MS) {
      this.refresh();
    } else {
      this.setTimers(access_token_expiration);
      setTimeout(() => this.setLastHttpDate(true), IdleService.DELAY_FOR_ROUTING_MS);
    }
  }
}
