@if (form) {
  <div class="institution-add-edit-view">
    <div class="heading">
      <h1 id="InstitutionAddEditView-Title">{{ title }}</h1>
      @if (mode !== 'add') {
        <div class="tenant-name" id="InstitutionAddEditView-TenantName">{{ myForm.tenant_name }}</div>
      }
    </div>
    <button
      mat-button
      color="primary"
      [routerLink]="['/', routePath.institutions, { tenant_name: myForm.tenant_name }]"
      id="InstitutionAddEditView-BackButton"
    >
      <span class="icon-arrow-left2"></span><span>&nbsp;Back to Institution List</span>
    </button>
    <div class="divider top">
      <mat-divider></mat-divider>
    </div>
    <form [formGroup]="form">
      @if (application?.multiple_locations) {
        <ng-container *ngTemplateOutlet="multipleLocations12_"></ng-container>
      } @else {
        <ng-container *ngTemplateOutlet="main_"></ng-container>
        <ng-container *ngTemplateOutlet="format_"></ng-container>
        <ng-container *ngTemplateOutlet="gateway_"></ng-container>
        <ng-container *ngTemplateOutlet="contact_"></ng-container>
        <ng-container *ngTemplateOutlet="application_"></ng-container>
      }
      @if (mode !== 'view') {
        <ng-container *ngTemplateOutlet="buttons_"></ng-container>
      }
    </form>
  </div>
}

<ng-template #section_ let-sectionTitle>
  <div class="section-title">
    {{ sectionTitle }}
  </div>
  <div class="divider top">
    <mat-divider></mat-divider>
  </div>
</ng-template>

<ng-template #main_>
  <div class="section">
    <div>
      <label
        for="InstitutionAddEditView-InstitutionNameText"
        id="InstitutionAddEditView-InstitutionNameLabel"
        class="required"
        >Institution Name</label
      >
      <input
        class="field-input"
        [formControl]="controls.tenant_name"
        id="InstitutionAddEditView-InstitutionNameText"
        maxlength="25"
      />
      @if (hasError(controls.tenant_name, 'required')) {
        <div class="error-text" id="InstitutionAddEditView-InstitutionNameRequiredFieldLabel">
          {{ error.required }}
        </div>
      } @else {
        @if (hasError(controls.tenant_name, 'pattern')) {
          <div class="error-text" id="InstitutionAddEditView-InvalidInstitionNameFormatLabel">
            {{ error.generic }}
          </div>
        }
      }
    </div>
    <div>
      <label class="required" for="InstitutionAddEditView-CustomerIdText" id="InstitutionAddEditView-CustomerIdLabel"
        >Customer ID</label
      >
      <input
        class="field-input"
        [formControl]="controls.customer_id"
        id="InstitutionAddEditView-CustomerIdText"
        maxlength="10"
      />
      @if (hasError(controls.customer_id, 'required')) {
        <div class="error-text" id="InstitutionAddEditView-CustomerIdRequiredFieldLabel">
          {{ error.required }}
        </div>
      } @else {
        @if (hasError(controls.customer_id, 'pattern')) {
          <div class="error-text" id="InstitutionAddEditView-InvalidInstitutionIdFormatLabel">
            {{ error.generic }}
          </div>
        }
      }
    </div>
    <div>
      <label class="required" for="InstitutionAddEditView-Status" id="InstitutionAddEditView-StatusLabel">Status</label>
      <div id="InstitutionAddEditView-Status" class="flex-group">
        @for (key of statusDisplayKeys; track key) {
          <div>
            <input
              type="radio"
              name="InstitutionAddEditView-StatusName"
              [value]="key"
              [formControl]="controls.status"
              [id]="'InstitutionEditor-' + idFormatterService.toInterCaps(key) + 'Radio'"
            />
            <label class="radio-label" [for]="'InstitutionEditor-' + idFormatterService.toInterCaps(key) + 'Radio'">{{
              statusDisplay[key]
            }}</label>
          </div>
        }
      </div>
    </div>
    <div>
      <label class="required" for="InstitutionAddEditView-Login" id="InstitutionAddEditView-LoginLabel">Login</label>
      <div id="InstitutionAddEditView-Login" class="flex-group">
        @for (key of loginDisplayKeys; track key) {
          <div>
            <input
              type="radio"
              name="InstitutionAddEditView-LoginName"
              [value]="key"
              [formControl]="controls.login_type"
              [id]="'InstitutionEditor-' + idFormatterService.toInterCaps(key) + 'Radio'"
            />
            <label class="radio-label" [for]="'InstitutionEditor-' + idFormatterService.toInterCaps(key) + 'Radio'">{{
              loginDisplay[key]
            }}</label>
          </div>
        }
      </div>
    </div>
    @if (application) {
      <div>
        <label for="InstitutionAddEditView-CountrySelect" id="InstitutionAddEditView-CountryLabel" class="required"
          >Country</label
        >
        <select class="field-input" [formControl]="controls.country" id="InstitutionAddEditView-CountrySelect">
          @for (kv of application.countries; track kv) {
            <option [value]="kv.key">{{ kv.value }}</option>
          }
        </select>
        @if (hasError(controls.country, 'required')) {
          <div class="error-text" id="InstitutionAddEditView-CountryRequiredFieldLabel">
            {{ error.required }}
          </div>
        }
      </div>
    }
    <div>
      <label for="InstitutionAddEditView-RegionText" id="InstitutionAddEditView-RegionLabel">Region</label>
      <input
        class="field-input"
        [formControl]="controls.region"
        id="InstitutionAddEditView-RegionText"
        maxlength="25"
      />
      @if (hasError(controls.region, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-InvalidRegionFormatLabel">{{ error.generic }}</div>
      }
    </div>
    @if (application) {
      <div>
        <label for="InstitutionAddEditView-LanguageSelect" id="InstitutionAddEditView-LanguageLabel" class="required"
          >Language</label
        >
        <select class="field-input" [formControl]="controls.language" id="InstitutionAddEditView-LanguageSelect">
          @for (kv of application.languages; track kv) {
            <option [value]="kv.key">{{ kv.value }}</option>
          }
        </select>
        @if (hasError(controls.language, 'required')) {
          <div class="error-text" id="InstitutionAddEditView-LanguageRequiredFieldLabel">
            {{ error.required }}
          </div>
        }
      </div>
      @if (!application.multiple_locations) {
        <div>
          <label class="required" for="InstitutionAddEditView-TimeZoneSelect" id="InstitutionAddEditView-TimeZoneLabel"
            >Time Zone</label
          >
          <select class="field-input" [formControl]="controls.time_zone" id="InstitutionAddEditView-TimeZoneSelect">
            @for (kv of application.time_zones; track kv) {
              <option [value]="kv.key">{{ kv.value }}</option>
            }
          </select>
          @if (hasError(controls.time_zone, 'required')) {
            <div class="error-text" id="InstitutionAddEditView-TimeZoneRequiredFieldLabel">
              {{ error.required }}
            </div>
          }
        </div>
      }
    }
  </div>
</ng-template>

<ng-template #contact_>
  <ng-container *ngTemplateOutlet="section_; context: { $implicit: 'Contact Info' }"> </ng-container>
  <div class="section">
    <div>
      <label for="InstitutionAddEditView-LastNameText" id="InstitutionAddEditView-LastNameLabel">Last Name</label>
      <input
        class="field-input"
        [formControl]="controls.contact_lastname"
        id="InstitutionAddEditView-LastNameText"
        maxlength="40"
      />
      @if (hasError(controls.contact_lastname, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-InvalidLastNameFormatLabel">{{ error.generic }}</div>
      }
    </div>
    <div>
      <label for="InstitutionAddEditView-FirstNameText" id="InstitutionAddEditView-FirstNameLabel">First Name</label>
      <input
        class="field-input"
        [formControl]="controls.contact_firstname"
        id="InstitutionAddEditView-FirstNameText"
        maxlength="40"
      />
      @if (hasError(controls.contact_firstname, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-InvalidFirstNameFormatLabel">{{ error.generic }}</div>
      }
    </div>
    <div>
      <label for="InstitutionAddEditView-PrimaryContactEmailText" id="InstitutionAddEditView-PrimaryContactEmailLabel"
        >Primary Contact E-mail</label
      >
      <input
        class="field-input"
        [formControl]="controls.contact_email"
        id="InstitutionAddEditView-PrimaryContactEmailText"
        maxlength="241"
      />
      @if (hasError(controls.contact_email, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-InvalidEmailAddressFormatLabel">
          {{ error.email }}
        </div>
      }
    </div>
    <div>
      <label for="InstitutionAddEditView-PhoneText" id="InstitutionAddEditView-PhoneLabel">Phone</label>
      <input
        class="field-input"
        [formControl]="controls.contact_phone"
        id="InstitutionAddEditView-PhoneText"
        maxlength="30"
      />
      @if (hasError(controls.contact_phone, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-InvalidPhoneNumberFormatLabel">
          {{ error.phone }}
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #application_>
  <ng-container *ngTemplateOutlet="section_; context: { $implicit: 'Application' }"> </ng-container>
  <div class="section row-gap">
    <div>
      <label
        class="required"
        for="InstitutionAddEditView-ApplicationSelect"
        id="InstitutionAddEditView-ApplicationLabel"
        >Application</label
      >
      <select class="field-input" [formControl]="controls.application" id="InstitutionAddEditView-ApplicationSelect">
        @for (kv of applicationsService.names; track kv) {
          <option [value]="kv.key">{{ kv.value }}</option>
        }
      </select>
      @if (hasError(controls.application, 'required')) {
        <div class="error-text" id="InstitutionAddEditView-ApplicationRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
    </div>
    @if (application) {
      <div>
        <label class="required" for="InstitutionAddEditView-TierSelect" id="InstitutionAddEditView-TierLabel"
          >Tier</label
        >
        <select class="field-input" [formControl]="controls.tier" id="InstitutionAddEditView-TierSelect">
          @for (kv of application.tiers; track kv) {
            <option [value]="kv.key">{{ kv.value }}</option>
          }
        </select>
        @if (hasError(controls.tier, 'required')) {
          <div class="error-text" id="InstitutionAddEditView-TierRequiredFieldLabel">
            {{ error.required }}
          </div>
        }
      </div>
      @if (application.options.length) {
        <div>
          <label for="InstitutionAddEditView-OptionsSelect" id="InstitutionAddEditView-OptionsLabel">Options</label>
          <mat-select
            class="field-input"
            [formControl]="controls.license_options"
            multiple
            id="InstitutionAddEditView-OptionsSelect"
          >
            @for (kv of application.options; track kv) {
              <mat-option [value]="kv.key">{{ kv.value }}</mat-option>
            }
          </mat-select>
        </div>
      }
      @if (application.regulatory_entities.length) {
        <div>
          <label
            class="required"
            for="InstitutionAddEditView-RegulatoryEntitySelect"
            id="InstitutionAddEditView-RegulatoryEntityLabel"
            >Regulatory Entity</label
          >
          <select
            class="field-input"
            [formControl]="controls.regulatory_entity"
            id="InstitutionAddEditView-RegulatoryEntitySelect"
          >
            @for (kv of application.regulatory_entities; track kv) {
              <option [value]="kv.key">{{ kv.value }}</option>
            }
          </select>
          @if (hasError(controls.regulatory_entity, 'required')) {
            <div class="error-text" id="InstitutionAddEditView-RegulatoryEntityRequiredFieldLabel">
              {{ error.required }}
            </div>
          }
        </div>
      }
    }
    <div>
      <label
        for="InstitutionAddEditView-LicenseStartDateText"
        id="InstitutionAddEditView-LicenseStartDateLabel"
        class="required"
        >License Start Date</label
      >
      <input
        class="field-input"
        type="date"
        [formControl]="controls.license_start_date"
        id="InstitutionAddEditView-LicenseStartDateText"
      />
      @if (hasError(controls.license_start_date, 'required')) {
        <div class="error-text" id="InstitutionAddEditView-LicenseStartDateRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
    </div>
    <div>
      <label
        for="InstitutionAddEditView-LicenseExpirationDateText"
        id="InstitutionAddEditView-LicenseExpirationDateLabel"
        class="required"
        >License Expiration Date</label
      >
      <input
        class="field-input"
        type="date"
        [formControl]="controls.license_expiration_date"
        id="InstitutionAddEditView-LicenseExpirationDateText"
      />
      @if (hasError(controls.license_expiration_date, 'required')) {
        <div class="error-text" id="InstitutionAddEditView-LicenseExpirationDateRequiredFieldLabel">
          {{ error.required }}
        </div>
      } @else {
        @if (controls.license_expiration_date.getError('error')) {
          <div class="error-text" id="InstitutionAddEditView-LicenseExpirationDateDateRangeLabel">
            {{ controls.license_expiration_date.getError('error') }}
          </div>
        }
      }
    </div>
  </div>
</ng-template>

<ng-template #gateway_>
  <ng-container *ngTemplateOutlet="section_; context: { $implicit: 'Gateway / Installation Information' }">
  </ng-container>
  <div class="section">
    <div>
      <label for="InstitutionAddEditView-GatewayKeyText" id="InstitutionAddEditView-GatewayKeyLabel">Gateway Key</label>
      <div class="flex-group">
        <input
          class="field-input"
          placeholder="Viewable after save"
          [formControl]="controls.gateway_key"
          id="InstitutionAddEditView-GatewayKeyText"
        />
        @if (controls.gateway_key.value) {
          <button
            id="InstitutionAddEditView-GatewayKeyCopyButton"
            mat-icon-button
            color="primary"
            (click)="actionClipboard('gateway_key')"
            [matTooltip]="copyTooltip"
          >
            <span class="icon-copy"></span>
          </button>
        }
      </div>
    </div>
    <div>
      <label for="InstitutionAddEditView-ClientIdText" id="InstitutionAddEditView-ClientIdLabel">Client ID</label>
      <div class="flex-group">
        <input
          class="field-input"
          [formControl]="controls.client_id"
          id="InstitutionAddEditView-ClientIdText"
          placeholder="Viewable after save"
        />
        @if (controls.client_id.value) {
          <button
            id="InstitutionAddEditView-ClientIdCopyButton"
            mat-icon-button
            color="primary"
            (click)="actionClipboard('client_id')"
            [matTooltip]="copyTooltip"
          >
            <span class="icon-copy"></span>
          </button>
        }
      </div>
    </div>
    <div>
      <label for="InstitutionAddEditView-ClientSecretText" id="InstitutionAddEditView-ClientSecretLabel"
        ><span>Client Secret</span>
        @if (controls.client_secret.value) {
          <span
            id="InstitutionAddEditView-ClientSecretIconEyeButton"
            class="icon-eye"
            matTooltip="Click to toggle visibility"
            (click)="clientSecretVisible = !clientSecretVisible"
          ></span>
        }
      </label>
      <div class="flex-group">
        <input
          [type]="clientSecretVisible ? 'text' : 'password'"
          class="field-input"
          placeholder="Viewable after save"
          [formControl]="controls.client_secret"
          id="InstitutionAddEditView-ClientSecretText"
        />
        @if (controls.client_secret.value) {
          <button
            id="InstitutionAddEditView-ClientSecretCopyButton"
            mat-icon-button
            color="primary"
            (click)="actionClipboard('client_secret')"
            [matTooltip]="copyTooltip"
          >
            <span class="icon-copy"></span>
          </button>
        }
      </div>
    </div>
  </div>
</ng-template>
<ng-template #buttons_>
  <div class="buttons">
    <button
      id="InstitutionAddEditView-SaveButton"
      class="save-button"
      mat-flat-button
      [disabled]="!(form.dirty && form.valid)"
      color="primary"
      (click)="actionSave()"
    >
      Save
    </button>
    <button
      id="InstitutionAddEditView-CancelButton"
      class="cancel-button"
      mat-stroked-button
      color="primary"
      [routerLink]="['/', routePath.institutions]"
    >
      Cancel
    </button>
  </div>
</ng-template>
<ng-template #format_>
  @if (application) {
    <ng-container *ngTemplateOutlet="section_; context: { $implicit: 'Number / Date Format' }"> </ng-container>
    <div class="section">
      <div>
        <label
          class="required"
          for="InstitutionAddEditView-NumberFormatSelect"
          id="InstitutionAddEditView-NumberFormatLabel"
          >Number Format</label
        >
        <select
          class="field-input"
          [formControl]="controls.number_format"
          id="InstitutionAddEditView-NumberFormatSelect"
        >
          @for (kv of application.number_formats; track kv) {
            <option [value]="kv.key">{{ kv.value }}</option>
          }
        </select>
        @if (hasError(controls.number_format, 'required')) {
          <div class="error-text" id="InstitutionAddEditView-NumberFormatRequiredFieldLabel">
            {{ error.required }}
          </div>
        }
      </div>
      <div>
        <label
          class="required"
          for="InstitutionAddEditView-DateFormatSelect"
          id="InstitutionAddEditView-DateFormatLabel"
          >Date Format</label
        >
        <select class="field-input" [formControl]="controls.date_format" id="InstitutionAddEditView-DateFormatSelect">
          @for (kv of application.date_formats; track kv) {
            <option [value]="kv.key">{{ kv.value }}</option>
          }
        </select>
        @if (hasError(controls.date_format, 'required')) {
          <div class="error-text" id="InstitutionAddEditView-DateFormatRequiredFieldLabel">
            {{ error.required }}
          </div>
        }
      </div>
    </div>
  }
</ng-template>
<!-- Multiple locations == 1.2. Segregate here. -->
<ng-template #multipleLocations12_>
  <ng-container *ngTemplateOutlet="main_"></ng-container>
  <ng-container *ngTemplateOutlet="gateway_"></ng-container>
  <ng-container *ngTemplateOutlet="contact_"></ng-container>
  <ng-container *ngTemplateOutlet="application12_"></ng-container>
</ng-template>

<ng-template #application12_>
  <ng-container *ngTemplateOutlet="section_; context: { $implicit: 'Application' }"> </ng-container>
  <div class="section row-gap">
    <div>
      <label
        class="required"
        for="InstitutionAddEditView-ApplicationSelect"
        id="InstitutionAddEditView-ApplicationLabel"
        >Application</label
      >
      <select class="field-input" [formControl]="controls.application" id="InstitutionAddEditView-ApplicationSelect">
        @for (kv of applicationsService.names; track kv) {
          <option [value]="kv.key">{{ kv.value }}</option>
        }
      </select>
      @if (hasError(controls.application, 'required')) {
        <div class="error-text" id="InstitutionAddEditView-ApplicationRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
    </div>
  </div>
</ng-template>
