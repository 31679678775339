<div class="profile-bar">
  <div class="icon-cloud"></div>
  <div class="stratus-logo"></div>
  <div class="profile-container" [matMenuTriggerFor]="menu" (click)="isMenuOpen = true">
    <div class="user-info">
      @if (currentUser()?.access_token_expiration) {
        <div class="welcome" id="ProfileBar-WelcomeLabel">
          <span>Welcome,&nbsp;</span><span class="login-user-name">{{ currentUser()?.first_name }}</span>
        </div>
        <sub class="user-role" id="ProfileBar-LoginRoleLabel">{{
          currentUser()?.role === adminRole ? 'Werfen Cloud Administrator' : currentUser()?.role
        }}</sub>
      }
    </div>
    <div class="icon-angle-up" [@chevronState]="isMenuOpen ? 'open' : 'closed'" id="ProfileBar-ChevronIcon"></div>
  </div>
</div>

<mat-menu #menu="matMenu" yPosition="below" (closed)="isMenuOpen = false">
  <div mat-menu-item (click)="showAbout()" id="ProfileBar-AboutWerfenCloudButton">About Werfen Cloud</div>
  <div mat-menu-item (click)="logout()" class="separated-menu-item" id="ProfileBar-LogoutButton">Logout</div>
</mat-menu>
