import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IdleService } from '../services/idle.service';
import { inject } from '@angular/core';

/**
 * For each http request, inject into the idle service, which will save a timestamp.
 */
export function idleInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const idleService = inject(IdleService);

  idleService.setLastHttpDate();

  return next(request);
}
